// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import store, { persistor } from "./setup/redux/storeConfig/store";
// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { ToastContainer } from "react-toastify";
import { AbilityContext } from "./setup/context/Can";
import { ThemeContext } from "./setup/context/ThemeColors";
import { IntlProviderWrapper } from "./setup/context/Internationalization";
// ** Ripple Button
import "./@core/components/ripple-button";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";
import Spinner from "./@core/components/spinner/Fallback-spinner";
// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "./index.css";


// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Web3ContextProvider } from "./setup/context/Web3Context";
// ** Bootstrap * Bootstrap Icons
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <Web3ContextProvider>
      <GoogleOAuthProvider clientId="829345440191-jlj8p0g5qtlnonmfsb456vosqlcvdn6t.apps.googleusercontent.com">
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <IntlProviderWrapper>
                <LazyApp />
                <ToastContainer newestOnTop />
              </IntlProviderWrapper>
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </GoogleOAuthProvider>
    </Web3ContextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
