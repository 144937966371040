export const LIKE_NFT_REQUEST = "LIKE_NFT_REQUEST";
export const LIKE_NFT_SUCCESS = "LIKE_NFT_SUCCESS";
export const LIKE_NFT_FAILURE = "LIKE_NFT_FAILURE";

export const UNLIKE_NFT_REQUEST = "UNLIKE_NFT_REQUEST";
export const UNLIKE_NFT_SUCCESS = "UNLIKE_NFT_SUCCESS";
export const UNLIKE_NFT_FAILURE = "UNLIKE_NFT_FAILURE";

export const GET_LIKED_NFT_REQUEST = "GET_LIKED_NFT_REQUEST";
export const GET_LIKED_NFT_SUCCESS = "GET_LIKED_NFT_SUCCESS";
export const GET_LIKED_NFT_FAILURE = "GET_LIKED_FAILURE";

export const GET_SOLD_HISTORY_REQUEST = "GET_SOLD_HISTORY_REQUEST";
export const GET_SOLD_HISTORY_SUCCESS = "GET_SOLD_HISTORY_SUCCESS";
export const GET_SOLD_HISTORY_FAILURE = "GET_SOLD_HISTORY_FAILURE";

export const GET_MINT_HISTORY_REQUEST = "GET_MINT_HISTORY_REQUEST";
export const GET_MINT_HISTORY_SUCCESS = "GET_MINT_HISTORY_SUCCESS";
export const GET_MINT_HISTORY_FAILURE = "GET_MINT_HISTORY_FAILURE";

export const GET_VISITOR_HISTORY_REQUEST = "GET_VISITOR_HISTORY_REQUEST";
export const GET_VISITOR_HISTORY_SUCCESS = "GET_VISITOR_HISTORY_SUCCESS";
export const GET_VISITOR_HISTORY_FAILURE = "GET_VISITOR_HISTORY_FAILURE";

export const GET_SAVE_HISTORY_REQUEST = "GET_SAVE_HISTORY_REQUEST";
export const GET_SAVE_HISTORY_SUCCESS = "GET_SAVE_HISTORY_SUCCESS";
export const GET_SAVE_HISTORY_FAILURE = "GET_SAVE_HISTORY_FAILURE";

export const MINT_NFT_REQUEST = "MINT_NFT_REQUEST";
export const MINT_NFT_SUCCESS = "MINT_NFT_SUCCESS";
export const MINT_NFT_FAILURE = "MINT_NFT_FAILURE";

export const SOLD_NFT_REQUEST = "SOLD_NFT_REQUEST";
export const SOLD_NFT_SUCCESS = "SOLD_NFT_SUCCESS";
export const SOLD_NFT_FAILURE = "SOLD_NFT_FAILURE";

export const GET_COIN_DETAILS = "GET_COIN_DETAILS";
export const GET_COIN_CHART = "GET_COIN_CHART";

export const GET_ALL_NFTS_SUCCESS = "GET_ALL_NFTS_SUCCESS";
export const GET_ALL_NFTS_FAILURE = "GET_ALL_NFTS_FAILURE"

export const BUY_NFT_SUCCESS = "BUY_NFT_SUCCESS"
export const BUY_NFT_FAILURE = "BUY_NFT_FAILURE"

