export default {
  NETWORK: {
    97: {
      NAME: "BSC Testnet",
      SYMBOL: "BUSD",
      ID: 97,
      RPC_URI: "https://data-seed-prebsc-2-s1.binance.org:8545",
      BLOCK_EXPLORE_URL: "https://testnet.bscscan.com"
    },
    56: {
      NAME: "BSC Mainnet",
      SYMBOL: "BUSD",
      ID: 56,
      RPC_URI: "https://bsc-dataseed1.binance.org/",
      BLOCK_EXPLORE_URL: "https://bscscan.com"
    }
  },
  CONTRACTS: {
    97: {
      EPIC_NFT_CONTRACT_ADDRESS: "0x860226AF354DDF136a0A72dfF01d0225F960E594",
      RARE_NFT_CONTRACT_ADDRESS: "0x6D6075eFeF93FBCA07Ce4a00fC7Ce4ade8EE3fF4",
      LEGENDARY_NFT_CONTRACT_ADDRESS: "0xCC3ba1eFc4A3788e8cB443Be6ca7F6524c16281C",
      GUNS_NFT_CONTRACT_ADDRESS: "0x179312255FfEcCF1D678b07bf1544127B58e44AA",
      MARKET_CONTRACT_ADDRESS: "0xE930Af487a63CB3DF6178BCeC8Af540B39e3b7fD",
      BUSD_CONTRACT_ADDRESS: "0x6f5C14C2Ea2824280Da0a42D411D9511E9081A57"
    },
    56: {
      EPIC_NFT_CONTRACT_ADDRESS: "comming",
      RARE_NFT_CONTRACT_ADDRESS: "comming",
      LEGENDARY_NFT_CONTRACT_ADDRESS: "comming",
      GUNS_NFT_CONTRACT_ADDRESS: "comming",
      MARKET_CONTRACT_ADDRESS: "comming",
      BUSD_CONTRACT_ADDRESS: "comming"
    }
  }
}
