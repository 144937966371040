// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import user from './auth/user'
import game from './game';
import navbar from './navbar'
import nft from './nft'
import layout from './layout'
import drawer from "./drawer";
const rootReducer = combineReducers({
  auth,
  user,
  nft,
  navbar, 
  layout,
  drawer,
  game
})

export default rootReducer
