export const CREATE_GAME_EVENT_SUCCESS = "CREATE_GAME_EVENT_SUCCESS";

export const CREATE_GAME_EVENT_FAILURE = "CREATE_GAME_EVENT_FAILURE";

export const GET_ALL_GAME_EVENTS_SUCESS = "GET_ALL_GAME_EVENTS";

export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";

export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

export const UPDATE_GAME_EVENT_SUCCESS = "UPDATE_GAME_EVENT_SUCCESS ";

export const UPDATE_GAME_EVENT_FAILURE = "UPDATE_GAME_EVENT_FAILURE ";

export const DELETE_GAME_EVENT_SUCCESS = "DELETE_GAME_EVENT_SUCCESS ";

export const DELETE_GAME_EVENT_FAILURE = "DELETE_GAME_EVENT_FAILURE ";