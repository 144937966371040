
// register constants
export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";
export const RESET_MAIL_SUCCESS = "RESET_MAIL_SUCCESS";
export const RESET_MAIL_FAILURE = "RESET_MAIL_FAILURE";

// login contantns
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FETCH_ACCESS_TOKEN = "FETCH_ACCESS_TOKEN";
export const LOGOUT = "LOGOUT";

export const SET_USER_DATA = "SET_USER_DATA";

