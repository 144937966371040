import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_ACCESS_TOKEN,
  SET_USER_DATA,
  LOGOUT
} from "../../constants/auth"

const initialState = {
  authenticated: false,
  userData: null,
  userToken: {
    access_token: "",
    expire_in: 3600,
    refresh_token: "",
    token_type: "Bearer"
  },
  loading: false,
  error: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true }
    case LOGIN_SUCCESS:
      return { ...state, loading: false, authenticated: true, userData: action.payload.data, error: [] }
    case LOGIN_FAILURE:
      return { ...state, loading: false, authenticated: false, error: action.error }
    case LOGOUT:
      return { ...state, loading: false, authenticated: false, error: [] }
    case SET_USER_DATA:
      return { ...state, loading: false, authenticated: true, userData: action.payload, error: [] }
    case FETCH_ACCESS_TOKEN:
      return {
        ...state,
        loading: false,
        authenticated: true,
        userToken: action.payload.token,
        error: []
      }
    default:
      return state
  }
}

export default reducer;

