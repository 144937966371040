import Axios from 'axios'
import * as TokenService from '../../services/TokenService'

const accessToken = TokenService.getAccessToken().access_token
export const API_HOST = process.env.REACT_APP_NODE_API;

export default Axios.create({
  baseURL: API_HOST,
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  },
  timeout: 100000
})