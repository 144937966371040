/* eslint-disable no-use-before-define */

import React, { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3Modal from "web3modal";
import siteConfig from '../../configs/site.config';
import Torus from "@toruslabs/torus-embed";
import busdABI from '@src/configs/busd.json'
import axios from '../axios/SetupAxios'
import { SiweMessage } from 'siwe';

export const Web3Context = createContext();

const providerOptions = {
    walletconnect: {
        package: WalletConnect,
        options: {
            infuraId: "538f6602a3474dfab48d5e4728f98d13"
        }
    }
    // coinbasewallet: {
    //     package: CoinbaseWalletSDK, // Required
    //     options: {
    //         appName: "TripleoGame", // Required
    //         infuraId: "IN538f6602a3474dfab48d5e4728f98d13FURA_ID", // Required
    //         chainId: 97, // Optional. It defaults to 1 if not provided
    //         darkMode: true // Optional. Use dark theme, defaults to false
    //     }
    // },
    // torus: {
    //     package: Torus, // required
    //     options: {
    //         networkParams: {
    //             chainId: 1337, // optional
    //             networkId: 1337 // optional
    //         },
    //         config: {
    //         }
    //     }
    // }
};

export const API_HOST = process.env.REACT_APP_NODE_API;

export const Web3ContextProvider = ({ children }) => {
    const [account, setAccount] = useState();
    const [networkId, setNetworkId] = useState();
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [network, setNetwork] = useState();

    const domain = window.location.host;
    const origin = window.location.origin;

    const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions, // required
        theme: "dark"
    });
    const connectWallet = async () => {
        try {
            const provider = await web3Modal.connect();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            const chainId = network.chainId
            setProvider(provider);
            setLibrary(library);
            setNetwork(network);
            localStorage.setItem('chainId', chainId)
            setNetworkId(network.chainId)
            if (network.chainId === siteConfig.NETWORK[chainId].ID) {
                if (accounts.length !== 0) setAccount(accounts[0]);
                return Promise.resolve({
                    message: 'success',
                    account: accounts[0]
                })
            } else {
                setAccount();
                switchNetwork(97);
                return Promise.reject({ message: `Change network to BSC net` })
            }
        } catch (error) {
            return Promise.reject({ message: "Something went wrong." })
        }
    };

    async function createSiweMessage(address, statement) {
        const res = await axios.get(`${API_HOST}/user/nonce`);
        const message = new SiweMessage({
            domain,
            address,
            statement,
            uri: origin,
            version: '1',
            chainId: '1',
            nonce: await res.data.message
        });
        return message.prepareMessage();
    }

    async function signInWithEthereum() {
        try {
            const signer = library.getSigner();
            const message = await createSiweMessage(
                await signer.getAddress(),
                'Sign in with Ethereum to the app.'
            );
            const signature = await signer.signMessage(message);
            const res = await axios.post(`${API_HOST}/user/siwe`, JSON.stringify({ message, signature }));
        } catch (error) {
            disconnectWallet();
        }
    }

    const switchNetwork = async (chainId) => {
        try {
            await library.provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: ethers.utils.hexlify(chainId) }]
            });
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await library.provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: toHex(siteConfig.NETWORK.ID),
                                chainName: siteConfig.NETWORK.NAME,
                                rpcUrls: [siteConfig.RPC_URI],
                                blockExplorerUrls: [siteConfig.BLOCK_EXPLORE_URL]
                            }
                        ]
                    });
                } catch (addError) {
                    throw addError;
                }
            }
        }
    };

    useEffect(() => {
        if (provider?.on) {
            const handleAccountsChanged = (accounts) => {
                setAccount(accounts[0]);
            };

            const handleChainChanged = (chainId) => {
                setNetworkId(chainId);
            };

            const handleDisconnect = () => {
                disconnectWallet();
            };

            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("chainChanged", handleChainChanged);
            provider.on("disconnect", handleDisconnect);

            return () => {
                if (provider.removeListener) {
                    provider.removeListener("accountsChanged", handleAccountsChanged);
                    provider.removeListener("chainChanged", handleChainChanged);
                    provider.removeListener("disconnect", handleDisconnect);
                }
            };
        }

    }, [provider]);

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connectWallet();
        }
    }, [])

    const refreshState = () => {
        setAccount();
        setNetworkId();
        setNetwork();
    };

    const disconnectWallet = async () => {
        await web3Modal.clearCachedProvider();
        localStorage.removeItem('chainId')
        refreshState();
    }

    const getAccBalance = async () => {
        if (library) {
            if (account) {
                const balance = await library.getBalance(account);
                return Number(ethers.utils.formatEther(balance.toString()));
            }
        }
    };

    const getBusdContract = (providerOrSigner) => {
        const busdContract = new ethers.Contract(
            siteConfig.CONTRACTS[networkId].BUSD_CONTRACT_ADDRESS,
            busdABI,
            providerOrSigner
        );
        return busdContract;
    };

    const getBusdBalance = async () => {
        if (library) {
            if (account) {
                const contract = getBusdContract(library);
                const res = await contract.balanceOf(account);
                return Number(ethers.utils.formatEther(res.toString()));
            }
        }
    }

    const getBusdBalanceOfAccount = async (account) => {
        if (library) {
            if (account) {
                const contract = getBusdContract(library);
                const res = await contract.balanceOf(account);
                return Number(ethers.utils.formatEther(res.toString()));
            }
        }
    }

    return (
        <Web3Context.Provider
            value={{
                connectWallet,
                disconnectWallet,
                getAccBalance,
                getBusdBalance,
                getBusdBalanceOfAccount,
                getBusdContract,
                signInWithEthereum,
                switchNetwork,
                account,
                library,
                provider,
                networkId,
                network
            }}
        >
            {children}
        </Web3Context.Provider>
    );
};
