import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE
} from "../../constants/auth"

const initialState = { data: null, loading: true, error: [] }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_REQUEST:
      return { ...state, loading: true }
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: [] }
    case REGISTER_USER_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default reducer;