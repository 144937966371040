import {
    CREATE_GAME_EVENT_SUCCESS,
    CREATE_GAME_EVENT_FAILURE,
    GET_ALL_GAME_EVENTS_SUCESS,
    GET_EVENT_SUCCESS,
    GET_EVENT_FAILURE,
    UPDATE_GAME_EVENT_FAILURE,
    DELETE_GAME_EVENT_FAILURE
} from "../../constants/game";

const initialState = {
    events: [],
    event: {},
    error: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_GAME_EVENTS_SUCESS:
            return { ...state, events: action.payload };
        case CREATE_GAME_EVENT_SUCCESS:
            return { ...state };
        case CREATE_GAME_EVENT_FAILURE:
            return { ...state, error: action.error };
        case GET_EVENT_SUCCESS:
            return { ...state, event: action.payload };
        case GET_EVENT_FAILURE:
            return { ...state, error: action.error };
        case UPDATE_GAME_EVENT_FAILURE:
            return { ...state, error: action.error };
        case DELETE_GAME_EVENT_FAILURE:
            return { ...state, error: action.error };
        default:
            return state
    }
}

export default reducer;