import {
  LIKE_NFT_SUCCESS,
  LIKE_NFT_FAILURE,
  UNLIKE_NFT_SUCCESS,
  UNLIKE_NFT_FAILURE,
  GET_LIKED_NFT_SUCCESS,
  GET_LIKED_NFT_FAILURE,
  GET_SOLD_HISTORY_SUCCESS,
  GET_SOLD_HISTORY_FAILURE,
  GET_MINT_HISTORY_SUCCESS,
  GET_MINT_HISTORY_FAILURE,
  GET_VISITOR_HISTORY_SUCCESS,
  GET_VISITOR_HISTORY_FAILURE,
  GET_SAVE_HISTORY_SUCCESS,
  GET_SAVE_HISTORY_FAILURE,
  MINT_NFT_SUCCESS,
  MINT_NFT_FAILURE,
  SOLD_NFT_SUCCESS,
  SOLD_NFT_FAILURE,
  GET_COIN_DETAILS,
  GET_COIN_CHART,
  GET_ALL_NFTS_SUCCESS,
  GET_ALL_NFTS_FAILURE,
} from "../../constants/nft"
import { market_processed_table_keys } from "../../../../utility/constants"

const initialState = {
  loading: false,
  market_data_processed: [],
  allNFTs: [],
  coin_chart_data: [],
  saved_nfts: [],
  mint_history: [],
  sold_history: [],
  visit_history: [],
  error: []
}

const compileMarketData = (marketData) => {
  const data = {}

  for (let market_index = 0; market_index < market_processed_table_keys.length; market_index++) {
    const field = market_processed_table_keys[market_index]
    const entries = Object.entries(marketData[field])
    for (let i = 0; i < entries.length; i++) {
      const key = entries[i][0]
      const value = entries[i][1]
      if (Object.keys(data).includes(key)) {
        data[key] = { ...data[key], [field]: value, currency: key }
      } else {
        data[key] = { [field]: value, currency: key }
      }
    }
  }

  return Object.values(data)
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIKE_NFT_SUCCESS:
      const result = [action.payload.like, ...state.saved_nfts];
      return { ...state, saved_nfts: result };
    case LIKE_NFT_FAILURE:
      return { ...state, error: action.error }
    case UNLIKE_NFT_SUCCESS:
      const saved_nfts = state.saved_nfts.filter(tweet => tweet.contractAddress !== action.payload.unlike.contractAddress && tweet.itemId !== action.payload.unlike.itemId);
      return { ...state, saved_nfts };
    case UNLIKE_NFT_FAILURE:
      return { ...state, error: action.error }
    case GET_LIKED_NFT_SUCCESS:
      const likednfts = action.payload.likedNFTs;
      return { ...state, saved_nfts: likednfts };
    case GET_LIKED_NFT_FAILURE:
      return { ...state, error: action.error }
    case SOLD_NFT_SUCCESS:
      const soldNFT = [action.payload.soldNFT, ...state.sold_history];
      return { ...state, sold_history: soldNFT };
    case SOLD_NFT_FAILURE:
      return { ...state, error: action.error }
    case GET_SOLD_HISTORY_SUCCESS:
      const sold_history = action.payload.soldHistory;
      return { ...state, sold_history };
    case GET_SOLD_HISTORY_FAILURE:
      return { ...state, error: action.error }
    case MINT_NFT_SUCCESS:
      const mintNFT = [action.payload.mintNFT, ...state.mint_history];
      return { ...state, sold_history: mintNFT };
    case MINT_NFT_FAILURE:
      return { ...state, error: action.error }
    case GET_MINT_HISTORY_SUCCESS:
      const mint_history = action.payload.mintHistory;
      return { ...state, mint_history };
    case GET_MINT_HISTORY_FAILURE:
      return { ...state, error: action.error }
    case GET_VISITOR_HISTORY_SUCCESS:
      const visit_history = action.payload.visitHistory;
      return { ...state, visit_history };
    case GET_VISITOR_HISTORY_FAILURE:
      return { ...state, error: action.error }
    case GET_SAVE_HISTORY_SUCCESS:
      const save_history = action.payload.saveHistory;
      return { ...state, save_history };
    case GET_SAVE_HISTORY_FAILURE:
      return { ...state, error: action.error }
    case GET_COIN_DETAILS:
      const market_data_processed = compileMarketData(action.payload.market_data)
      return { ...state, market_data_processed }
    case GET_COIN_CHART:
      const coin_chart_data = action.payload;
      return { ...state, coin_chart_data }
    case GET_ALL_NFTS_SUCCESS:
      const allNFTs = action.payload;
      return { ...state, allNFTs }
    case GET_ALL_NFTS_FAILURE:
      return { ...state, error:action.error }
    default:
      return state
  }
}

export default reducer;

